import React, { useEffect } from 'react'
import MaskedInput from 'react-input-mask'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { BookingStep, Gender, NewPatientBookingForm, NewPatientFormElements } from '../../../models/BookingAppointment'
import { PatientDraft } from '../../../models/BookingAppointment'
import { PatientType } from '../../../models/enums'
import { CustomFieldType } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import {
    fetchPracticeLocationProcedures as fetchPracticeLocationProceduresAction,
    fetchProcedureProviders as fetchProcedureProvidersAction,
} from '../../practices/actions'
import ButtonGroupSelector from '../../shared/custom-fields/ButtonGroupSelector'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomInlineDatepicker from '../../shared/custom-fields/CustomInlineDatepicker'
import CustomMultiselectField from '../../shared/custom-fields/CustomMultiselectField'
import CustomPhoneInput from '../../shared/custom-fields/CustomPhoneInput'
import WebcodeLocationMultiselectContainer from '../../shared/custom-fields/webcode-location-multiselect/WebcodeLocationMultiselectContainer'
import { usePrevious } from '../../shared/custom-hooks'
import { isDobDate } from '../../shared/form-validator/isDateValid'
import { FormFieldElement, isBasicPhoneNumberWithWarning } from '../../shared/form-validator/validator'

import { appointmentSubscribeToChannel } from './actions'
import {
    createAppointment,
    setAppointmentBookingStep,
    setAppointmentPickerError,
    setBookingError,
    setPending,
    updateFormFields,
} from './actions'
import AppointmentPickerSection from './AppointmentPickerSection'
import { APP_ERROR_MESSAGE_TIMEOUT, PMS_WRITEBACK_DISABLED_MESSAGE } from './shared'

import './NewPatientTab.sass'

const DATE_FORMAT = 'MM/DD/YYYY'
const DATE_MASK = '99/99/9999'

export type NewPatientTabProps = {
    open: boolean
    isModalOpened: boolean
    practice: Models.Practice
    chat: Models.ChatMetadata
    webCode: Models.WebCode
    tab: PatientType
    directSchedulingRef: React.RefObject<HTMLDivElement>
    locationsSchedulingDataLoaded: boolean
    appointment?: Models.Appointment
    form: NewPatientBookingForm
    chatHasScheduledAppointment: boolean
    isAdditionalPatientBooking?: boolean
}

export type NewPatientTabDispatch = {
    fetchPracticeLocationProcedures: typeof fetchPracticeLocationProceduresAction
    fetchProcedureProviders: typeof fetchProcedureProvidersAction
    updateFormFields: typeof updateFormFields
    setAppointmentBookingStep: typeof setAppointmentBookingStep
    setPending: typeof setPending
    createAppointment: typeof createAppointment
    setAppointmentPickerError: typeof setAppointmentPickerError
    appointmentSubscribeToChannel: typeof appointmentSubscribeToChannel
    setBookingError: typeof setBookingError
    onSendMessage: (message: string) => void
    handleAddPatient: () => void
}

type Props = NewPatientTabProps & NewPatientTabDispatch

const moduleName = 'scheduling-new-patient-form'

const NewPatientTab = (props: Props) => {
    const { practice, fetchPracticeLocationProcedures, webCode, fetchProcedureProviders } = props
    const prevSelectedLocation = usePrevious(props.form.formElements.location.value)
    const selectedLocation = props.form.formElements.location.value
    const selectedProcedure = props.form.formElements.procedure.value
    const enabledProviderSelection = Boolean(webCode?.customization?.direct_scheduling?.enable_provider_selection)
    const isPMSWritebackEnabled = practice?.locations.find(location => location.id === selectedLocation)
        ?.isOnlineSchedulingWritebackEnabled
    const dispatch = useAppDispatch()

    const {
        chat,
        tab,
        form: { isPending },
    } = props

    useEffect(() => {
        if (selectedLocation && practice?.id) {
            const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
            const locationProcedures = practice.locations[locationIndex]?.procedures
            const shouldFetchProcedures = (selectedProcedure || selectedLocation) && !locationProcedures

            if (prevSelectedLocation !== selectedLocation || shouldFetchProcedures) {
                dispatch(fetchPracticeLocationProcedures(selectedLocation, practice))
            }
        }
    }, [practice, prevSelectedLocation, selectedLocation, selectedProcedure, fetchPracticeLocationProcedures, dispatch])

    useEffect(() => {
        if (isPending && isPMSWritebackEnabled) {
            const timer = setTimeout(() => {
                if (isPending) {
                    dispatch(props.setPending(chat, tab, false))
                    dispatch(props.setBookingError(chat, tab, APP_ERROR_MESSAGE_TIMEOUT))
                    dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.ERROR))
                }
            }, 20000)

            return () => clearTimeout(timer)
        }
        if (isPending && !isPMSWritebackEnabled) {
            const timer = setTimeout(() => {
                dispatch(props.setPending(chat, tab, false))
                dispatch(props.setBookingError(chat, tab, PMS_WRITEBACK_DISABLED_MESSAGE))
                dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.ERROR))
            }, 5000)

            return () => clearTimeout(timer)
        }

        return
    }, [isPending, dispatch])

    const getIsBooked = () => {
        return [BookingStep.BOOKED, BookingStep.SHARED].includes(props.form.bookingStep)
    }

    const handleSetAppointmentBookingStep = (step: BookingStep) => () => {
        const { chat, tab } = props
        dispatch(props.setAppointmentBookingStep(chat, tab, step))
    }

    const getProcedure = () => {
        const { practice, form } = props

        if (!practice?.locations) {
            return undefined
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures

        if (!locationProcedures || !locationProcedures[form.formElements.procedure.value]) {
            return undefined
        }

        return locationProcedures[form.formElements.procedure.value]
    }

    const getLocation = () => {
        const {
            practice,
            form: {
                formElements: {
                    location: { value: locationId },
                },
            },
        } = props

        if (!practice || !practice.locations || !locationId) {
            return undefined
        }

        return practice.locations.find(location => location.id === locationId)
    }

    const isLocationDisabled = (locationId: string): boolean => {
        const location = practice?.locations.find(location => location.id === locationId)
        return !Boolean(location?.hasDirectScheduling && location?.agentLocationActive)
    }

    const locationSchedulingStatusReason = (locationId: string): string => {
        const location = practice?.locations.find(location => location.id === locationId)
        if (location?.hasDirectScheduling == null && location?.agentLocationActive == null) {
            return ' - not paired to agent'
        }
        if (location?.agentLocationActive === false) {
            if (selectedLocation === locationId) {
                dispatch(
                    props.updateFormFields(props.chat, props.tab, {
                        ...props.form.formElements,
                        location: { ...props.form.formElements.location, value: '' },
                    }),
                )
            }
            return ' - offline agent'
        }
        if (location?.hasDirectScheduling === false) {
            return ' - DS not enabled'
        }

        if (location?.isOnlineSchedulingWritebackEnabled === false) {
            return ' - writeback set to off'
        }

        return ''
    }

    const onUpdate = (modifier: (changes: NewPatientFormElements) => NewPatientFormElements) => {
        if (props.tab === 'newPatient') {
            dispatch(props.updateFormFields(props.chat, props.tab, modifier(props.form.formElements)))
        }
    }

    const bookAppointment = async () => {
        const {
            chat,
            tab,
            form: {
                formElements,
                isFormValid,
                appointmentPicker: { selectedDatetime },
            },
        } = props
        const location = getLocation()
        const procedure = getProcedure()

        if (!isFormValid || !selectedDatetime || !location || !procedure) {
            return
        }

        dispatch(props.setPending(chat, tab, true))

        const createAppointmentPatientData: PatientDraft = {
            first_name: formElements.firstName.value.trim(),
            last_name: formElements.lastName.value.trim(),
            middie_initial: '',
            mobile_phone: formElements.mobilePhone.value,
            email_address: formElements.email.value,
            birth_date: formElements.dateOfBirth.value,
            home_phone: '',
        }

        if (formElements.gender?.value) {
            createAppointmentPatientData.gender = formElements.gender.value as Gender
        }

        const transactionId = uuidv4()

        dispatch(props.appointmentSubscribeToChannel(chat, tab, transactionId))

        dispatch(
            props.createAppointment(chat, tab, location, procedure, {
                datetime: selectedDatetime.toDate(),
                patient: createAppointmentPatientData,
                chat_id: chat.id,
                transactionId: transactionId,
                ...(formElements.provider.value ? { providerId: formElements.provider.value } : {}),
            }),
        )
            .catch((err: Error) => {
                dispatch(props.setAppointmentPickerError(chat, tab, err.message))
            })
            .finally(() => {})
    }

    const shareAppointment = (datetime: moment.Moment, postponed: boolean = false) => () => {
        const message = postponed
            ? `I have requested an appointment for ${props.form.formElements.firstName.value} ${
                  props.form.formElements.lastName.value
              } on ${datetime.format('dddd, MMMM D [at] h:mm a')}. I'll send you an email once it's scheduled.`
            : `${
                  !props.chatHasScheduledAppointment ? `Wonderful! ` : ``
              }I have booked an appointment for ${props.form.formElements.firstName.value.trim()} ${props.form.formElements.lastName.value.trim()} on ${datetime.format(
                  'dddd, MMMM D [at] h:mm a',
              )}. I'll send you an email once it's confirmed.`

        props.onSendMessage(message)

        dispatch(props.setAppointmentBookingStep(props.chat, props.tab, BookingStep.SHARED))
    }

    const hasError = (field: FormFieldElement) => field.isDirty && !field.isValid

    const hasWarning = (field: FormFieldElement) => field.isDirty && !field.isPossiblyInvalid

    const generateProceduresOptions = () => {
        const { practice } = props

        if (!practice?.locations) {
            return []
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures

        if (!locationProcedures) {
            return []
        }

        const procedureIds = Object.keys(locationProcedures)
        const filteredProcedureIds = procedureIds.filter(
            id =>
                locationProcedures[id].enabled === true && ['both', 'new'].includes(locationProcedures[id].patientType),
        )

        return filteredProcedureIds.map((id: string) => ({
            id,
            name: locationProcedures[id].name || '',
        }))
    }

    const renderProviders = () => {
        const { practice, form } = props

        if (!practice?.locations || !form.formElements.procedure.value) {
            return null
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures
        const selectedProcedure = locationProcedures?.[form.formElements.procedure.value]

        if (!selectedProcedure) {
            return null
        }

        return (
            <React.Fragment>
                {selectedProcedure.providers?.map((provider: Models.Provider) => (
                    <option key={provider.id} value={provider.id}>
                        {[provider.suffix, provider.first_name, provider.middle_initial, provider.last_name]
                            .filter(Boolean)
                            .join(' ')}
                    </option>
                ))}
            </React.Fragment>
        )
    }

    const {
        open,
        form: { formElements },
    } = props
    const disabled = getIsBooked()
    const procedureOptions = generateProceduresOptions()

    return (
        <div className={`${moduleName} ${!open ? `${moduleName}--hidden` : ''}`}>
            <div className={`${moduleName}__field-columns`}>
                <div className={`${moduleName}__field`}>
                    <CustomField
                        disabled={disabled}
                        required={true}
                        value={formElements.firstName.value}
                        customFieldType={CustomFieldType.INPUT}
                        error={hasError(formElements.firstName)}
                        errorMessage={formElements.firstName?.firstErrorMessage || 'This field is invalid'}
                        label="First Name*"
                        inputProps={{ maxLength: 15 }}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            onUpdate(elements => {
                                elements.firstName.value = event.target.value
                                elements.firstName.isDirty = true
                                return elements
                            })
                        }
                    />
                </div>

                <div className={`${moduleName}__field`}>
                    <CustomField
                        disabled={disabled}
                        required={true}
                        value={formElements.lastName.value}
                        customFieldType={CustomFieldType.INPUT}
                        error={hasError(formElements.lastName)}
                        errorMessage={formElements.lastName?.firstErrorMessage || 'This field is invalid'}
                        label="Last Name*"
                        inputProps={{ maxLength: 20 }}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            onUpdate(elements => {
                                elements.lastName.value = event.target.value
                                elements.lastName.isDirty = true
                                return elements
                            })
                        }
                    />
                </div>
            </div>

            <div className={`${moduleName}__field-columns ${moduleName}__field-columns--with-date`}>
                <div className={`${moduleName}__field ${moduleName}__field--datepicker`}>
                    <MaskedInput
                        mask={DATE_MASK}
                        value={formElements.dateOfBirth.value}
                        disabled={disabled}
                        onBlur={() => {
                            onUpdate(elements => {
                                elements.dateOfBirth.isDirty = true
                                return elements
                            })
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const { value: targetValue } = event.target as HTMLInputElement
                            onUpdate(elements => {
                                elements.dateOfBirth.value = targetValue
                                return elements
                            })
                        }}
                    >
                        {() => (
                            <input
                                value={formElements.dateOfBirth.value}
                                placeholder={DATE_FORMAT}
                                className={`${moduleName}__masked-input`}
                            />
                        )}
                    </MaskedInput>

                    <CustomInlineDatepicker
                        value={
                            formElements.dateOfBirth.value && isDobDate(formElements.dateOfBirth.value)
                                ? moment(formElements.dateOfBirth.value, DATE_FORMAT).toDate()
                                : null
                        }
                        label="Date of Birth*"
                        invalidLabel={DATE_FORMAT}
                        placeholder={DATE_FORMAT}
                        dateFormat={DATE_FORMAT}
                        disableFuture={true}
                        maxDateMessage="Future dates not supported"
                        onChange={(value: moment.Moment) => {
                            onUpdate(elements => {
                                elements.dateOfBirth.value = value.format(DATE_FORMAT)
                                elements.dateOfBirth.isDirty = true
                                return elements
                            })
                        }}
                    />
                    {formElements.dateOfBirth.isDirty && !formElements.dateOfBirth.isValid && (
                        <div className={`${moduleName}__error`}>Invalid date value</div>
                    )}
                </div>
                <div className={`${moduleName}__field ${moduleName}__field--gender`}>
                    <ButtonGroupSelector
                        items={[
                            {
                                key: Gender.MALE,
                                value: Gender.MALE,
                            },
                            {
                                key: Gender.FEMALE,
                                value: Gender.FEMALE,
                            },
                        ]}
                        value={formElements.gender?.value || ''}
                        keyProperty="key"
                        valueProperty="value"
                        label="Sex"
                        disabled={disabled}
                        onSelect={(value: string) => {
                            onUpdate(elements => {
                                elements.gender.value = value === formElements.gender.value ? '' : value
                                elements.gender.isDirty = true
                                return elements
                            })
                        }}
                    />
                </div>
            </div>

            <div className={`${moduleName}__field ${moduleName}__field--mobile-phone`}>
                <div className="phone-number">
                    <CustomPhoneInput
                        disabled={disabled}
                        error={hasError(formElements.mobilePhone)}
                        errorMessage={formElements.mobilePhone?.firstErrorMessage || 'Invalid phone number'}
                        warning={hasWarning(formElements.mobilePhone)}
                        warningMessage={'Phone # might be invalid'}
                        value={formElements.mobilePhone.value}
                        label="Mobile Phone*"
                        country={'US'}
                        onChange={(value: string) => {
                            onUpdate(elements => {
                                elements.mobilePhone.value = value
                                elements.mobilePhone.isDirty = true

                                return elements
                            })
                        }}
                        onCountryChange={(countryCode: string) => {
                            onUpdate(elements => {
                                elements.mobilePhone.validators = [isBasicPhoneNumberWithWarning({ countryCode })]

                                return elements
                            })
                        }}
                    />
                </div>
            </div>

            <div className={`${moduleName}__field`}>
                <CustomField
                    disabled={disabled}
                    required={true}
                    value={formElements.email.value}
                    customFieldType={CustomFieldType.INPUT}
                    error={hasError(formElements.email)}
                    errorMessage={formElements.email?.firstErrorMessage || 'Invalid email'}
                    label="Email*"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        onUpdate(elements => {
                            elements.email.value = event.target.value.trim()
                            return elements
                        })
                    }
                    onBlur={() => {
                        onUpdate(elements => {
                            elements.email.isDirty = true
                            return elements
                        })
                    }}
                />
            </div>

            <div className={`${moduleName}__field`}>
                <WebcodeLocationMultiselectContainer
                    disabled={disabled || props.isAdditionalPatientBooking}
                    label="Practice Location*"
                    practice={practice}
                    placeholder="Choose a Location"
                    webCode={webCode}
                    onlyEnabledWebCodeLocations={true}
                    selectedItems={formElements.location.value ? [formElements.location.value] : []}
                    error={hasError(formElements.location)}
                    errorMessage={formElements.location?.firstErrorMessage || 'This field is required'}
                    isOptionDisabled={isLocationDisabled}
                    locationSchedulingStatusReason={locationSchedulingStatusReason}
                    onSelectElement={(values: string[]) => {
                        onUpdate(elements => {
                            elements.location.value = values[0]
                            elements.location.isDirty = true
                            return elements
                        })

                        handleSetAppointmentBookingStep(BookingStep.NONE)
                    }}
                    loading={!props.locationsSchedulingDataLoaded}
                />
            </div>

            <div className={`${moduleName}__field`}>
                <CustomMultiselectField
                    items={procedureOptions}
                    search={procedureOptions.length > 8}
                    searchPlaceholder="Search Procedures"
                    maxSelected={1}
                    selectedItems={[formElements.procedure.value || '']}
                    keyProperty="id"
                    displayProperty="name"
                    placeholder="Choose Reason"
                    label="Reason for Appointment*"
                    error={hasError(formElements.procedure)}
                    errorMessage={formElements.procedure?.firstErrorMessage?.trim() || 'This field is required'}
                    disabled={disabled || !Boolean(selectedLocation)}
                    onSelectElement={(values: string[]) => {
                        const value = values[0] || ''
                        onUpdate(elements => {
                            elements.procedure.value = value
                            elements.procedure.isDirty = true
                            elements.provider.value = ''
                            elements.provider.isDirty = true
                            return elements
                        })

                        handleSetAppointmentBookingStep(BookingStep.NONE)
                        if (formElements.location.value && formElements.procedure.value) {
                            dispatch(
                                fetchProcedureProviders(
                                    formElements.location.value,
                                    practice,
                                    practice.locations.find(el => el.id === formElements.location.value)?.procedures?.[
                                        value
                                    ] as Models.Procedure,
                                ),
                            )
                        }
                    }}
                />
            </div>

            {enabledProviderSelection && (
                <div className={`${moduleName}__field`}>
                    <CustomField
                        disabled={disabled || !Boolean(selectedLocation) || !formElements.procedure.value}
                        customFieldType={CustomFieldType.SELECT}
                        label="Provider"
                        value={formElements.provider.value}
                        error={hasError(formElements.provider)}
                        errorMessage={formElements.provider?.firstErrorMessage || 'This field is required'}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            onUpdate(elements => {
                                elements.provider.value = event.target.value
                                elements.provider.isDirty = true
                                return elements
                            })

                            handleSetAppointmentBookingStep(BookingStep.NONE)
                        }}
                    >
                        <option value={''} selected={formElements.provider.value === ''}>
                            Any Provider
                        </option>
                        {renderProviders()}
                    </CustomField>
                </div>
            )}

            <AppointmentPickerSection
                patientName={`${formElements.firstName.value} ${formElements.lastName.value}`}
                form={props.form}
                appointment={props.appointment}
                practice={props.practice}
                isNewPatient={true}
                handleSetAppointmentBookingStep={handleSetAppointmentBookingStep}
                shareAppointment={shareAppointment}
                bookAppointment={bookAppointment}
                addPatient={props.handleAddPatient}
                directSchedulingRef={props.directSchedulingRef}
            />
        </div>
    )
}

export default NewPatientTab
